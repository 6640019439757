import React, { useState, useReducer } from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import InputGroup from 'react-bootstrap/InputGroup';

import OrgsList from './OrgsList';
import * as Icons from '../icons';

import './ModuleForm.css';

const UPDATE_FIELD = 'UPDATE_FIELD';

function formReducer(values, { type, field, value }) {
  switch (type) {
    case UPDATE_FIELD:
      return { ...values, [field]: value };
    default:
      return values;
  }
}

const NoIcon = () => <span>Icon</span>;

const ModuleForm = ({ initialValues, children, onSubmit }) => {
  const [values, dispatch] = useReducer(formReducer, initialValues);
  const [bundle, setBundle] = useState(null);
  const [filename, setFilename] = useState('');
  const [type, setType] = useState('');

  const handleFileChange = e => {
    const [bundle] = e.target.files;
    setBundle(bundle);
    setFilename(bundle.name);
    setType(bundle.type);
  };

  const handleModuleTypeChange = e => {
    if (e.target.checked) {
      dispatch({
        type: UPDATE_FIELD,
        field: 'moduleType',
        value: e.target.value
      });
    }
  };

  const handleScopesChange = e => {
    dispatch({
      type: UPDATE_FIELD,
      field: 'scopes',
      value: e.target.value.replace(/\n/g, ' ')
    });
  };

  const handleOrgsChange = orgs => {
    dispatch({
      type: UPDATE_FIELD,
      field: 'orgs',
      value: orgs
    });
  };

  const handleChange = ({ target: { type, id, value, checked } }) => {
    dispatch({
      type: UPDATE_FIELD,
      field: id,
      value: type === 'checkbox' ? checked : value
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    onSubmit({ ...values, newBundle: bundle, filename, type });
  };

  const ActiveIcon = Icons[values.icon] || NoIcon;

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Row>
        <Form.Group as={Col} xs="12" sm="2" controlId="icon">
          <Form.Label>Icon</Form.Label>
          <Dropdown
            onSelect={icon => {
              dispatch({
                type: UPDATE_FIELD,
                field: 'icon',
                value: icon
              });
            }}
          >
            <Dropdown.Toggle
              id="icon-dropdown"
              variant="outline-secondary"
              block
            >
              <ActiveIcon />
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ maxHeight: '80vh', overflowY: 'auto' }}>
              {Object.keys(Icons).map(key => {
                const Tag = Icons[key];
                return (
                  <Dropdown.Item key={key} eventKey={key}>
                    <Tag /> {key}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </Form.Group>
        <Form.Group as={Col} xs="12" sm="10" controlId="name">
          <Form.Label>Name</Form.Label>
          <Form.Control required value={values.name} onChange={handleChange} />
        </Form.Group>
      </Form.Row>
      <Form.Group controlId="description">
        <Form.Label>Description</Form.Label>
        <Form.Control
          required
          value={values.description}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group controlId="path">
        <Form.Label>Path</Form.Label>
        <Form.Text className="text-muted mt-0 mb-2">
          Specify the beginning portion of the URL pathname. Example:{' '}
          <span className="text-primary">
            <b>/foo</b>
          </span>
          /bar/baz
        </Form.Text>
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text>/</InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            required
            value={values.path.replace(/^\//, '')}
            onChange={handleChange}
            disabled={values.moduleId}
          />
        </InputGroup>
      </Form.Group>
      <Form.Group controlId="scopes">
        <Form.Label>Scopes</Form.Label>
        <Form.Text className="text-muted mt-0 mb-2">
          Enter scopes on separate lines. If <i>any</i> of the scopes listed are
          granted to the user they'll be able to access the module.
        </Form.Text>
        <Form.Control
          as="textarea"
          value={values.scopes.replace(/\s/g, '\n')}
          onChange={handleScopesChange}
        />
      </Form.Group>
      {values.bundle && (
        <Form.Group>
          <Form.Label>Current Bundle</Form.Label>
          <Form.Control plaintext readOnly defaultValue={values.bundle} />
        </Form.Group>
      )}
      <Form.Group>
        <Form.Label>{values.bundle ? 'New Bundle' : 'Bundle'}</Form.Label>
        <Form.Text className="text-muted mt-0 mb-2">
          This should be a single Javascript bundle that will render to a div
          with an id of "root".
        </Form.Text>
        <Form.Control
          type="file"
          required={!values.moduleId}
          onChange={handleFileChange}
        />
      </Form.Group>
      <Form.Group controlId="moduleType">
        <Form.Label>Module type</Form.Label>
        <Form.Text className="text-muted mt-0 mb-2">
          A <i>Portal</i> module (default) will appear under the modules section
          for authorized orgs. A <i>User</i> module will appear under the
          username dropdown for all users. An <i>Admin</i> module will appear
          under the admin section for portal admins.
        </Form.Text>
        <div className="d-flex w-100 btn-group btn-group-toggle">
          <label
            htmlFor="moduleType-portal"
            className={`btn btn-outline-secondary ${
              values.moduleType === 'portal' ? 'active' : ''
            }`}
          >
            <input
              type="radio"
              name="moduleType"
              id="moduleType-portal"
              value="portal"
              autoComplete="off"
              checked={values.moduleType === 'portal'}
              onChange={handleModuleTypeChange}
            />{' '}
            Portal
          </label>
          <label
            htmlFor="moduleType-user"
            className={`btn btn-outline-secondary ${
              values.moduleType === 'user' ? 'active' : ''
            }`}
          >
            <input
              type="radio"
              name="moduleType"
              id="moduleType-user"
              value="user"
              autoComplete="off"
              checked={values.moduleType === 'user'}
              onChange={handleModuleTypeChange}
            />{' '}
            User
          </label>
          <label
            htmlFor="moduleType-admin"
            className={`btn btn-outline-secondary ${
              values.moduleType === 'admin' ? 'active' : ''
            }`}
          >
            <input
              type="radio"
              name="moduleType"
              id="moduleType-admin"
              value="admin"
              autoComplete="off"
              checked={values.moduleType === 'admin'}
              onChange={handleModuleTypeChange}
            />{' '}
            Admin
          </label>
        </div>
      </Form.Group>
      {values.moduleType === 'portal' && (
        <OrgsList value={initialValues.orgs} onChange={handleOrgsChange} />
      )}
      {values.enabled != null && (
        <Form.Group controlId="enabled">
          <Form.Check
            custom
            className="custom-switch"
            type="checkbox"
            label="Enabled"
            checked={values.enabled}
            onChange={handleChange}
          />
        </Form.Group>
      )}
      {children}
    </Form>
  );
};

export default ModuleForm;
