import React, { useMemo, useReducer, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';

import {
  StateContext,
  DispatchContext,
  MODULES_BUSY,
  MODULES_RESOLVED,
  MODULES_REJECTED,
  modulesReducer,
  initialModulesState
} from './ModulesContext';

import {
  OrgsStateContext,
  OrgsDispatchContext,
  ORGS_LOADING,
  ORGS_LOADED,
  ORGS_ERRORED,
  orgsReducer,
  initialOrgsState
} from './OrgsContext';

import { usePrevious } from '../hooks';

import ListModules from './ListModules';
import CreateModule from './CreateModule';
import EditModule from './EditModule';

import './modules.css';

export default ({ ctx }) => {
  const isPortalAdmin = /portal:admin/.test(ctx.scopes);
  const isModulesAdmin = /modules:admin/.test(ctx.scopes);
  const [state, dispatch] = useReducer(modulesReducer, initialModulesState);
  const [orgsState, orgsDispatch] = useReducer(orgsReducer, initialOrgsState);
  const previousIsStale = usePrevious(orgsState.isStale);
  const memoizedCtx = useMemo(() => {
    return {
      apiBaseUrl: ctx.apiBaseUrl,
      accessToken: ctx.accessToken,
      userId: ctx.userId,
      orgId: ctx.orgId,
      refreshToken: ctx.refreshToken
    };
  }, [
    ctx.apiBaseUrl,
    ctx.accessToken,
    ctx.userId,
    ctx.orgId,
    ctx.refreshToken
  ]);

  useEffect(() => {
    dispatch({ type: MODULES_BUSY });
    fetch(`${memoizedCtx.apiBaseUrl}/modules`)
      .then(x => x.json())
      .then(payload => dispatch({ type: MODULES_RESOLVED, payload }))
      .catch(() => dispatch({ type: MODULES_REJECTED }));

    orgsDispatch({ type: ORGS_LOADING });
    fetch(`${memoizedCtx.apiBaseUrl}/auth/all-orgs`, {
      headers: {
        Authorization: `Bearer ${memoizedCtx.accessToken}`,
        'x-fv-userid': memoizedCtx.userId,
        'x-fv-orgid': memoizedCtx.orgId,
        'x-fv-sessionid': memoizedCtx.refreshToken
      }
    })
      .then(x => x.json())
      .then(({ data }) => orgsDispatch({ type: ORGS_LOADED, payload: data }))
      .catch(() => orgsDispatch({ type: ORGS_ERRORED }));
  }, [
    memoizedCtx.accessToken,
    memoizedCtx.apiBaseUrl,
    memoizedCtx.orgId,
    memoizedCtx.refreshToken,
    memoizedCtx.userId
  ]);

  useEffect(() => {
    if (orgsState.isStale && orgsState.isStale !== previousIsStale) {
      orgsDispatch({ type: ORGS_LOADING });
      fetch(`${memoizedCtx.apiBaseUrl}/auth/all-orgs`, {
        headers: {
          Authorization: `Bearer ${memoizedCtx.accessToken}`,
          'x-fv-userid': memoizedCtx.userId,
          'x-fv-orgid': memoizedCtx.orgId,
          'x-fv-sessionid': memoizedCtx.refreshToken
        }
      })
        .then(x => x.json())
        .then(({ data }) => orgsDispatch({ type: ORGS_LOADED, payload: data }))
        .catch(() => orgsDispatch({ type: ORGS_ERRORED }));
    }
  }, [
    memoizedCtx.accessToken,
    memoizedCtx.apiBaseUrl,
    memoizedCtx.orgId,
    memoizedCtx.refreshToken,
    memoizedCtx.userId,
    orgsState.isStale,
    previousIsStale
  ]);

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        <OrgsStateContext.Provider value={orgsState}>
          <OrgsDispatchContext.Provider value={orgsDispatch}>
            <div className="modules-wrapper">
              <Switch>
                <Route
                  path="/systemmodules/new"
                  exact
                  render={routeProps => (
                    <CreateModule
                      {...routeProps}
                      isModulesAdmin={isModulesAdmin}
                      isPortalAdmin={isPortalAdmin}
                    />
                  )}
                />
                <Route
                  path="/systemmodules/:id"
                  exact
                  render={routeProps => (
                    <EditModule
                      {...routeProps}
                      isModulesAdmin={isModulesAdmin}
                      isPortalAdmin={isPortalAdmin}
                    />
                  )}
                />
                <Route
                  path="/systemmodules"
                  exact
                  render={routeProps => (
                    <ListModules
                      {...routeProps}
                      isModulesAdmin={isModulesAdmin}
                      isPortalAdmin={isPortalAdmin}
                    />
                  )}
                />
              </Switch>
            </div>
          </OrgsDispatchContext.Provider>
        </OrgsStateContext.Provider>
      </DispatchContext.Provider>
    </StateContext.Provider>
  );
};
