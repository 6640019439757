import { createContext } from 'react';

export const initialModulesState = {
  isWorking: true,
  isError: false,
  data: []
};

export const StateContext = createContext(initialModulesState);
export const DispatchContext = createContext(() => {});

export const MODULES_BUSY = 'MODULES_BUSY';
export const MODULES_RESOLVED = 'MODULES_RESOLVED';
export const MODULES_REJECTED = 'MODULES_REJECTED';

export const SYSTEM_MODULES = [
  '/batch',
  '/keys',
  '/orgs',
  '/profile',
  '/security',
  '/subscriptions',
  '/users'
];

export function modulesReducer(state, { type, payload }) {
  switch (type) {
    case MODULES_BUSY:
      return { ...state, isWorking: true, isError: false };
    case MODULES_RESOLVED:
      return { ...state, isWorking: false, data: payload };
    case MODULES_REJECTED:
      return { ...state, isWorking: false, isError: true };
    default:
      return state;
  }
}
