import styled from 'styled-components';

const FormContainer = styled.div`
  text-align: center;
  overflow: hidden;
  width: 100%;
  height: calc(100vh - 1.5rem);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(90, 92, 105);
  background-image: linear-gradient(rgb(90, 92, 105) 10%, rgb(55, 56, 64) 100%);
  background-size: cover;
  margin: 0px;
`;

export default FormContainer;
