export default {
  container: {},
  formContainer: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#5a5c69',
    backgroundImage: 'linear-gradient(180deg, #5a5c69 10%, #373840 100%)',
    backgroundSize: 'cover',
    margin: '0px'
  },
  formSection: {},
  formField: {},

  sectionHeader: {},
  sectionBody: {},
  sectionFooter: {},
  sectionFooterPrimaryContent: {},
  sectionFooterSecondaryContent: {},

  input: {},
  button: {
    backgroundColor: 'var(--primary)',
    borderColor: 'var(--primary)',
    borderRadius: '3px'
  },
  photoPickerButton: {},
  photoPlaceholder: {},
  signInButton: {},
  signInButtonIcon: {
    display: 'none'
  },
  signInButtonContent: {},
  amazonSignInButton: {},
  facebookSignInButton: {},
  googleSignInButton: {},
  oAuthSignInButton: {},

  formRow: {},
  strike: {},
  strikeContent: {},
  actionRow: {},
  a: {},

  hint: {},
  radio: {},
  label: {},
  inputLabel: {},
  toast: {},

  navBar: {},
  nav: {},
  navRight: {},
  navItem: {},
  navButton: {}
};
