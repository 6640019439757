import React, { useContext, useReducer, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import ListGroup from 'react-bootstrap/ListGroup';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

import {
  OrgsStateContext,
  OrgsDispatchContext,
  ORGS_STALE
} from './OrgsContext';
import { usePrevious } from '../hooks';
import AsteriskIcon from '../icons/AsteriskIcon';
import ImportantIcon from '../icons/ImportantIcon';

const SELECTED = 'SELECTED';
const DESELECTED = 'DESELECTED';
const ALL_SELECTED = 'ALL_SELECTED';
const ALL_DESELECTED = 'ALL_DESELECTED';

const initialSelectedState = [];

const selectedReducer = (state, action) => {
  switch (action.type) {
    case SELECTED:
      return [...state, action.payload];
    case DESELECTED:
      return state.filter(id => id !== action.payload);
    case ALL_SELECTED:
      return action.payload;
    case ALL_DESELECTED:
      return initialSelectedState;
    default:
      return state;
  }
};

const OrgsList = ({ value, onChange }) => {
  const orgsState = useContext(OrgsStateContext);
  const orgsDispatch = useContext(OrgsDispatchContext);
  const [selectedState, dispatchSelect] = useReducer(selectedReducer, value);
  const previousSelectedState = usePrevious(selectedState);

  const handleToggle = e => {
    dispatchSelect({
      type: e.target.checked ? SELECTED : DESELECTED,
      payload: e.target.id
    });
  };

  const handleSelectAll = () => {
    dispatchSelect({
      type: ALL_SELECTED,
      payload: orgsState.data.map(org => org.pk)
    });
  };

  const handleDeselectAll = () => {
    dispatchSelect({
      type: ALL_DESELECTED
    });
  };

  const handleRefresh = () => {
    orgsDispatch({ type: ORGS_STALE });
  };

  const hasNoOrgs =
    !orgsState.isLoading &&
    Array.isArray(orgsState.data) &&
    orgsState.data.length === 0;

  const hasOrgs =
    !orgsState.isLoading &&
    Array.isArray(orgsState.data) &&
    orgsState.data.length > 0;

  useEffect(() => {
    if (selectedState !== previousSelectedState) {
      onChange(selectedState);
    }
  }, [onChange, selectedState, previousSelectedState]);

  return (
    <Form.Group controlId="orgs" className="animated fadeIn">
      <Form.Label>Orgs</Form.Label>
      <Form.Text className="text-muted mt-0">
        If none are selected, then the module is enabled for <i>all</i> orgs.
        This means that when new orgs are enabled in the portal they'll
        automatically have the module enabled. If <i>any</i> orgs are selected,
        new orgs will have to be added manually.
      </Form.Text>
      <Form.Row className="my-2">
        <Col>
          <Button
            block
            disabled={orgsState.isLoading || orgsState.isError}
            variant="outline-primary"
            onClick={handleSelectAll}
          >
            Select all
          </Button>
        </Col>
        <Col>
          <Button
            block
            disabled={orgsState.isLoading || orgsState.isError}
            variant="outline-primary"
            onClick={handleDeselectAll}
          >
            Deselect all
          </Button>
        </Col>
        <Col>
          <Button
            block
            disabled={orgsState.isLoading}
            variant="outline-primary"
            onClick={handleRefresh}
          >
            Refresh
          </Button>
        </Col>
      </Form.Row>
      <ListGroup>
        {orgsState.isLoading && (
          <ListGroup.Item variant="info" className="d-flex align-items-center">
            <Spinner animation="border" variant="info" size="sm" />
            <span className="ml-2">Loading orgs...</span>
          </ListGroup.Item>
        )}
        {orgsState.isError && (
          <ListGroup.Item
            variant="danger"
            className="d-flex align-items-center"
          >
            <ImportantIcon as="span" className="icon--danger" />
            <span className="ml-2">There was a problem loading the orgs!</span>
          </ListGroup.Item>
        )}
        {hasNoOrgs && (
          <ListGroup.Item
            variant="warning"
            className="d-flex align-items-center"
          >
            <AsteriskIcon as="span" className="icon--warning" />
            <span className="ml-2">There are no orgs available.</span>
          </ListGroup.Item>
        )}
        {hasOrgs &&
          orgsState.data.map(org => (
            <ListGroup.Item key={org.pk}>
              <Form.Check
                custom
                className="custom-switch"
                type="checkbox"
                id={org.pk}
                label={org.name}
                checked={selectedState.includes(org.pk)}
                onChange={handleToggle}
              />
            </ListGroup.Item>
          ))}
      </ListGroup>
    </Form.Group>
  );
};

export default OrgsList;
