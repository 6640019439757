import React from 'react';
import Container from 'react-bootstrap/Container';
import Jumbotron from 'react-bootstrap/Jumbotron';

export default function Home() {
  return (
    <Container>
      <Jumbotron className="my-3">
        <h1>Welcome!</h1>
        <p>
          This is the Filevine Developer Portal, where you can manage your API
          keys and subscriptions, among other things.
        </p>
      </Jumbotron>
    </Container>
  );
}
