import React from 'react';
import { I18n } from 'aws-amplify';
import { TOTPSetupComp } from 'aws-amplify-react';

import './MyTOTPSetupComp.css';

var mySessionExpireTimer = null;

export default class MyTOTPSetupComp extends TOTPSetupComp {
  render() {
    const { code, setupMessage } = this.state;
    
    if(!mySessionExpireTimer) {
      if(code){
        mySessionExpireTimer = setTimeout(() => {
          // Auth flow has expired. Redirecting to login page.
          window.location.reload();
        }, 175000); // 2min 55seconds

        console.log('Started session expire timer');  
      }
    } else if (setupMessage && setupMessage === 'Setup TOTP successfully!'){
      clearTimeout(mySessionExpireTimer);
      console.log('Cleared session expire timer');
    }

    return (
      <div className="fv__formContainer">
        <div className="fv__formSection">
          <div className="fv__sectionHeader">
            <span>{I18n.get('Scan then enter verification code')}</span>{' '}
            <a
              href="https://support.filevine.com/hc/en-us/articles/360034352952"
              rel="noopener noreferrer"
              target="_blank"
            >
              {I18n.get('Need help?')}
            </a>
          </div>
          <div className="fv__sectionBody">
            {this.showSecretCode(code, this.props.theme)}
            {setupMessage && I18n.get(setupMessage)}
          </div>
          <div className="fv__sectionFooter">
            <button
              type="button"
              className="fv__button"
              onClick={this.verifyTotpToken}
            >
              {I18n.get('Verify Security Token')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}