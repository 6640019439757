import React from 'react';
import styled, { keyframes } from 'styled-components';
import { I18n } from 'aws-amplify';
import { SignIn } from 'aws-amplify-react';

import FormContainer from '../components/FormContainer';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.span`
  width: 1rem;
  height: 1rem;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-top-color: rgba(255, 255, 255, 1);
  border-radius: 100%;
  animation: ${spin} 1s linear infinite;
`;

const FormSection = styled.div`
  position: relative;
  margin-bottom: 20px;
  background-color: var(--form-color);
  padding: 35px 40px;
  text-align: left;
  display: inline-block;
  min-width: 460px;
  border-radius: 6px;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.15);
  box-sizing: border-box;

  @media only screen and (max-width: 599px) {
    width: 100%;
    box-sizing: border-box;
    padding: 35px 25px;
    box-shadow: none;
    border-radius: 0;
    min-width: auto;
  }
`;

const SectionHeader = styled.div`
  color: var(--deepSquidInk);
  margin-bottom: 24px;
  font-size: 18px;
  font-weight: 500;
`;

const SectionBody = styled.div`
  margin-bottom: 30px;
`;

const FormField = styled.div`
  margin-bottom: 22px;
`;

const InputLabel = styled.div`
  color: var(--input-color);
  font-size: 14px;
  margin-bottom: 8px;
`;

const Input = styled.input`
  display: block;
  width: 100%;
  padding: 16px;
  font-size: 14px;
  color: var(--input-color);
  background-color: var(--input-background-color);
  background-image: none;
  border: 1px solid var(--lightGrey);
  border-radius: 3px;
  box-sizing: border-box;
  margin-bottom: 10px;

  &:disabled {
    color: #545454;
    background-color: var(--silver);
  }

  @media only screen and (max-width: 599px) {
    font-size: 16px;
  }
`;

const Hint = styled.div`
  color: var(--grey);
  font-size: 12px;
`;

const Link = styled.a`
  color: inherit;
  text-decoration: none;
  cursor: pointer;
`;

const SectionFooter = styled.div`
  font-size: 14px;
  color: var(--grey);
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;

  @media only screen and (max-width: 599px) {
    flex-wrap: wrap;
  }
`;

const SectionFooterPrimaryContent = styled.span`
  margin-left: auto;

  @media only screen and (max-width: 599px) {
    width: 100%;
    margin-bottom: 32px;
  }
`;

const Button = styled.button`
  width: 100%;
  background-color: var(--primary);
  border-color: var(--primary);
  border-radius: 3px;
  min-width: 153px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  background-image: none;
  color: var(--button-color);
  text-transform: uppercase;
  padding: 14px 0;
  letter-spacing: 1.1px;
  border: none;
`;

export default class MySignIn extends SignIn {
  showComponent(theme) {
    return React.createElement(
      FormContainer,
      { theme: theme },
      React.createElement(
        FormSection,
        { theme: theme },
        React.createElement(
          SectionHeader,
          {
            theme: theme
          },
          I18n.get('Sign in to your account')
        ),
        React.createElement(
          'form',
          { onSubmit: this.signIn },
          React.createElement(
            'fieldset',
            { disabled: this.state.loading },
            React.createElement(
              SectionBody,
              { theme: theme },
              this.renderUsernameField(theme),
              React.createElement(
                FormField,
                { theme: theme },
                React.createElement(
                  InputLabel,
                  { theme: theme },
                  I18n.get('Password'),
                  ' *'
                ),
                React.createElement(Input, {
                  placeholder: I18n.get('Enter your password'),
                  theme: theme,
                  key: 'password',
                  type: 'password',
                  name: 'password',
                  onChange: this.handleInputChange
                }),
                React.createElement(
                  Hint,
                  { theme: theme },
                  I18n.get('Forget your password? '),
                  React.createElement(
                    Link,
                    {
                      theme: theme,
                      onClick: () => {
                        return this.changeState('forgotPassword');
                      }
                    },
                    I18n.get('Reset password')
                  )
                )
              )
            )
          ),
          React.createElement(
            SectionFooter,
            {
              theme: theme
            },
            React.createElement(
              SectionFooterPrimaryContent,
              { theme: theme },
              React.createElement(
                Button,
                {
                  theme: theme,
                  type: 'submit',
                  disabled: this.state.loading
                },
                this.state.loading
                  ? React.createElement(Spinner)
                  : I18n.get('Sign In')
              )
            )
          )
        )
      )
    );
  }
}
