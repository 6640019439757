import { Auth } from 'aws-amplify';

import { tokenRevokedAction } from './authReducer';

export const constructHeaders = tokens => ({
  Authorization: `Bearer ${tokens.accessToken}`,
  'x-fv-userid': tokens.userId,
  'x-fv-orgid': tokens.orgId,
  'x-fv-sessionid': tokens.refreshToken
});

export default function requestMiddleware() {
  return ({ dispatch, getState }) => next => action => {
    const { request } = action;

    if (!request) {
      return next(action);
    }

    const authState = getState().auth || {};
    const {
      accessToken,
      accessTokenExpired,
      refreshToken,
      refreshTokenExpired,
      userId,
      orgId
    } = authState;

    if (accessTokenExpired && !refreshTokenExpired && refreshToken) {
      return Auth.currentSession()
        .then(session => {
          dispatch({ type: 'cognito/REFRESHED', payload: { session } });

          const {
            idToken: { jwtToken: newAccessToken }
          } = session;
          request({
            ...session,
            headers: constructHeaders({
              accessToken: newAccessToken,
              refreshToken,
              userId,
              orgId
            })
          });
        })
        .catch(err => {
          console.error('unable to auto-refresh token:', err);
          dispatch(tokenRevokedAction());
          return Auth.signOut();
        });
    }

    return request({
      accessToken,
      refreshToken,
      userId,
      orgId,
      headers: constructHeaders(authState)
    });
  };
}
