import ms from 'ms';
import jwt from 'jsonwebtoken';

const THIRTY_DAYS = 1000 * 60 * 60 * 24 * 30;

export const initialState = {
  // status
  now: stamp(),
  busy: false,
  error: null,

  // context
  username: '',
  userId: null,
  orgId: null,
  orgs: [],
  activeOrg: null,

  // jwt
  accessToken: null,
  accessTokenExpiry: 0,
  accessTokenExpired: true,
  accessTokenDecoded: null,

  // refresh
  refreshToken: null,
  refreshTokenExpired: true,
  refreshTokenExpiry: 0,
  refreshTokenTtl: '0s'
};

function authReducer(state = initialState, action) {
  let nextState;

  switch (action.type) {
    case 'cognito/INITIALIZED': {
      const {
        session: {
          idToken: { jwtToken: accessToken },
          refreshToken: { token: refreshToken }
        },
        context: { email: username, userId, orgs }
      } = action.payload;

      const refreshTokenExpiry = Math.floor((Date.now() + THIRTY_DAYS) / 1000);

      nextState = {
        ...state,
        username,
        accessToken,
        refreshToken,
        refreshTokenExpiry,
        refreshTokenTtl: ms(THIRTY_DAYS, { long: true }),
        userId,
        orgId: (orgs.find(o => o.orgId === state.orgId) || orgs[0]).orgId,
        orgs: orgs.map(o => ({
          pk: o.id,
          id: o.orgId,
          name: o.name,
          roleIds: o.roleIds,
          orgId: o.orgId,
          userId: o.apiUserId
        })),
        busy: false
      };
      break;
    }
    case 'cognito/REFRESHED': {
      const {
        session: {
          idToken: { jwtToken: accessToken },
          refreshToken: { token: refreshToken }
        }
      } = action.payload;

      const refreshTokenExpiry = Math.floor((Date.now() + THIRTY_DAYS) / 1000);

      nextState = {
        ...state,
        accessToken,
        refreshToken,
        refreshTokenExpiry,
        refreshTokenTtl: ms(THIRTY_DAYS, { long: true }),
        busy: false
      };
      break;
    }
    case 'auth/ORGID_CHANGED':
      nextState = { ...state, orgId: action.payload };
      break;
    case 'auth/TOKEN_REVOKED':
      return initialState;
    default:
      nextState = state;
  }

  return applyCalculatedState(nextState);
}

function applyCalculatedState(state) {
  const now = stamp();
  const { orgs = [], orgId, accessToken, refreshTokenExpiry } = state;

  // if we have an access token, let's store the decoded data
  const accessTokenDecoded = accessToken ? jwt.decode(accessToken) : null;

  // calculate expirations
  const { exp: accessTokenExpiry = 0 } = accessTokenDecoded || {};
  const accessTokenExpired = accessTokenExpiry < now;
  const refreshTokenExpired = refreshTokenExpiry < now;

  const refreshTtl = refreshTokenExpired ? 0 : refreshTokenExpiry - now;
  const refreshTokenTtl = ms(refreshTtl * 1000, { long: true });

  const accessTtl = accessTokenExpired ? 0 : accessTokenExpiry - now;
  const accessTokenTtl = ms(accessTtl * 1000, { long: true });

  // active org
  const activeOrg = orgs.find(o => o.id === orgId);
  const activeOrgScopes =
    (accessTokenDecoded && accessTokenDecoded[`fv:org:${orgId}`]) || '';

  return {
    ...state,
    activeOrg,
    now,
    isPortalAdmin: activeOrgScopes.indexOf('portal:admin') > -1,
    isOrgAdmin: activeOrgScopes.indexOf('org:admin') > -1,
    valid: !refreshTokenExpired && !state.busy,
    refreshTokenExpired,
    refreshTokenTtl,
    accessTokenTtl,
    accessTokenDecoded,
    accessTokenScopes: activeOrgScopes,
    accessTokenExpiry,
    accessTokenExpired
  };
}

export function updateOrgIdAction(orgId) {
  return {
    type: 'auth/ORGID_CHANGED',
    payload: orgId
  };
}

export function tokenRevokedAction() {
  return { type: 'auth/TOKEN_REVOKED' };
}

function stamp() {
  return Math.floor(Date.now() / 1000);
}

export default authReducer;
