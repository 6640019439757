const initialState = {
  loading: true,
  data: []
};

export default function(state = initialState, action) {
  if (action.type === 'modules/REQUESTED') {
    return initialState;
  }

  if (action.type === 'modules/CACHED') {
    return { loading: false, data: action.payload };
  }

  if (action.type === 'modules/CLEARED') {
    return { loading: false, data: [] };
  }

  return state;
}
