import React, { useMemo } from 'react';
import { IndexLinkContainer, LinkContainer } from 'react-router-bootstrap';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Spinner from 'react-bootstrap/Spinner';
import Dropdown from 'react-bootstrap/Dropdown';
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';
import delve from 'dlv';
import { push } from 'connected-react-router';

import { tokenRevokedAction, updateOrgIdAction } from './redux/authReducer';
import { ReactComponent as Logo } from './filevine-white-new.svg';
import { usePrevious } from './hooks';

import * as Icons from './icons';

const ModuleIcon = ({ icon, name }) => {
  const Icon = Icons[icon] || React.Fragment;
  return (
    <span className="d-flex align-items-center">
      <Icon />
      <span className="ml-2">{name}</span>
    </span>
  );
};

const NavbarHeading = ({ children }) => (
  <Navbar.Text className="mt-3 mb-0 px-2 h6 font-weight-light text-muted text-uppercase">
    {children}
  </Navbar.Text>
);

function Navigation({
  isPortalAdmin,
  ctx,
  pathname,
  modules,
  modulesLoading,
  dispatch,
  orgs,
  activeOrg,
  username,
  busy,
  valid
}) {
  const activeOrgId = delve(activeOrg, 'id');
  const prevActiveOrgId = usePrevious(activeOrgId);

  const handleLogout = key => {
    if (key === 'logout') {
      dispatch(push('/'));
      dispatch(tokenRevokedAction());
      Auth.signOut();
    }
  };

  useMemo(() => {
    const fetchModules = (orgId, ctx) => {
      console.log('requesting modules for org:', orgId, ctx.scopes);

      dispatch({ type: 'modules/REQUESTED' });

      fetch(`${ctx.apiBaseUrl}/modules`, {
        headers: {
          Authorization: `Bearer ${ctx.accessToken}`,
          'x-fv-userid': ctx.userId,
          'x-fv-orgid': ctx.orgId,
          'x-fv-sessionid': ctx.refreshToken
        }
      })
        .then(x => x.json())
        .then(modules => {
          const orgModules = modules
            .concat(
              isPortalAdmin || /modules:admin/.test(ctx.scopes)
                ? {
                    icon: 'Code',
                    name: 'System Modules',
                    description: 'System Modules',
                    path: '/systemmodules',
                    enabled: true,
                    admin: true,
                    scopes: 'portal:admin modules:admin'
                  }
                : null
            )
            .filter(
              mod =>
                mod &&
                mod.enabled &&
                (!mod.orgs || mod.orgs.indexOf(ctx.orgPk) > -1) &&
                (!mod.scopes ||
                  ctx.scopes.split(' ').some(s => mod.scopes.indexOf(s) > -1))
            );

          dispatch({
            type: 'modules/CACHED',
            payload: orgModules
          });
        })
        .catch(error => {
          console.error('Unable to load modules:', error);
        });
    };

    const shouldFetch =
      ctx &&
      ctx.hasOwnProperty('accessToken') &&
      activeOrgId &&
      activeOrgId !== prevActiveOrgId;
    if (shouldFetch) {
      fetchModules(activeOrgId, ctx);
    }
  }, [ctx, activeOrgId, prevActiveOrgId, dispatch, isPortalAdmin]);

  const loggedIn = valid && !busy;
  const activeModule = Array.isArray(modules)
    ? modules.find(m => pathname.indexOf(m.path) > -1)
    : null;
  const viewingUserModule = delve(activeModule, 'user', false);
  const hasMultipleOrgs = orgs.length > 1;

  const sortModulesByName = (a, b) => a.name.localeCompare(b.name);
  const userModules = modules.filter(m => m.user).sort(sortModulesByName);
  const hasUserModules = userModules.length > 0;
  const orgModules = modules
    .filter(m => !m.user && !m.admin)
    .sort(sortModulesByName);
  const hasOrgModules = orgModules.length > 0;
  const adminModules = modules
    .filter(m => !m.user && m.admin)
    .sort(sortModulesByName);
  const hasAdminModules = adminModules.length > 0;

  return (
    <Navbar
      variant="dark"
      bg="dark"
      expand="md"
      className={`navigation flex-md-column ${
        loggedIn
          ? 'justify-content-between'
          : 'w-100 w-md-50 justify-content-center'
      }`}
      collapseOnSelect
    >
      <IndexLinkContainer to="/">
        <Navbar.Brand
          className={`logo py-0 mr-md-0 px-md-2 mb-md-3 ${
            loggedIn ? 'align-self-md-start' : 'align-self-md-center'
          }`}
        >
          <Logo width={200} />
        </Navbar.Brand>
      </IndexLinkContainer>
      {loggedIn && (
        <>
          <Navbar.Toggle aria-controls="nav" />
          <Navbar.Collapse id="nav" className="w-100 align-items-md-start">
            <Nav className="flex-column nav-pills w-100">
              <Dropdown onSelect={handleLogout}>
                <Dropdown.Toggle
                  variant={viewingUserModule ? 'primary' : 'dark'}
                  id="profile-split"
                  className="px-2 mt-2 w-100 d-flex align-items-center justify-content-between"
                >
                  {username}
                </Dropdown.Toggle>
                <Dropdown.Menu className="w-100">
                  {userModules.map(mod => (
                    <LinkContainer key={mod.path} to={mod.path}>
                      <Dropdown.Item
                        eventKey="profile"
                        active={false}
                        title={mod.description}
                      >
                        <ModuleIcon {...mod} />
                      </Dropdown.Item>
                    </LinkContainer>
                  ))}
                  {hasUserModules && <Dropdown.Divider />}
                  <Dropdown.Item eventKey="logout" active={false}>
                    <ModuleIcon icon="DoorExit" path="/logout" name="Logout" />
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              {modulesLoading ? (
                <div className="text-secondary d-flex align-items-center">
                  <Spinner
                    animation="border"
                    variant="secondary"
                    size="sm"
                    className="mx-2 my-3"
                  />
                  {' Loading modules'}
                </div>
              ) : (
                <>
                  <NavbarHeading>Organization</NavbarHeading>
                  {hasMultipleOrgs ? (
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="dark"
                        id="org-toggle"
                        className="px-2 w-100 d-flex align-items-center justify-content-between"
                      >
                        {activeOrg.name}
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="w-100">
                        {orgs.map(org => (
                          <div
                            key={org.id}
                            onClick={() => {
                              dispatch(
                                push('/' + (pathname.split('/')[1] || ''))
                              );
                              dispatch(updateOrgIdAction(org.id));
                            }}
                          >
                            <Dropdown.Item
                              className={
                                activeOrg.id === org.id
                                  ? 'font-weight-bold'
                                  : ''
                              }
                            >
                              {org.name}
                            </Dropdown.Item>
                          </div>
                        ))}
                        <Dropdown.Divider />
                        <Dropdown.Header>Missing an org?</Dropdown.Header>
                        <Dropdown.Item
                          href={`mailto:api-help@filevine.com?subject=Authorize an org for ${username} in the Developer Portal`}
                        >
                          Contact Support
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : (
                    <Navbar.Text className="px-2 text-muted">
                      {activeOrg.name}
                    </Navbar.Text>
                  )}

                  {hasOrgModules && (
                    <>
                      <NavbarHeading>Modules</NavbarHeading>
                      {orgModules.map(mod => (
                        <LinkContainer
                          key={mod.path}
                          to={mod.path}
                          className="px-2"
                        >
                          <Nav.Link active={false} title={mod.description}>
                            <ModuleIcon {...mod} />
                          </Nav.Link>
                        </LinkContainer>
                      ))}
                    </>
                  )}
                  {hasAdminModules && (
                    <>
                      <NavbarHeading>Admin</NavbarHeading>
                      {adminModules.map(mod => (
                        <LinkContainer
                          key={mod.path}
                          to={mod.path}
                          className="px-2"
                        >
                          <Nav.Link active={false} title={mod.description}>
                            <ModuleIcon {...mod} />
                          </Nav.Link>
                        </LinkContainer>
                      ))}
                    </>
                  )}
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </>
      )}
    </Navbar>
  );
}

export default connect(state => ({
  isPortalAdmin: state.auth.isPortalAdmin,
  pathname: state.router.location.pathname,
  activeOrg: state.auth.activeOrg || {},
  username: state.auth.username,
  valid: state.auth.valid,
  busy: state.auth.busy,
  orgs: state.auth.orgs,
  modules: state.modules.data,
  modulesLoading: state.modules.loading,
  ctx: {
    apiBaseUrl: process.env.REACT_APP_GATEWAY_URL,
    apiUserId: delve(state, 'auth.activeOrg.userId', ''),
    userId: state.auth.userId,
    orgId: state.auth.orgId,
    orgPk: delve(state, 'auth.activeOrg.pk', ''),
    accessToken: state.auth.accessToken,
    refreshToken: state.auth.refreshToken,
    scopes: state.auth.accessTokenScopes
  }
}))(Navigation);
