import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import Container from 'react-bootstrap/Container';
import Alert from 'react-bootstrap/Alert';

import { usePrevious } from '../hooks';

const ModuleFrame = ({ location, dispatch, ctx, moduleId, path, bundle }) => {
  const stylesheets = [
    ...document.querySelectorAll('head > link[rel="stylesheet"]')
  ];
  const styleNodes = [...document.querySelectorAll('head > style')];
  const iframeEl = useRef(null);
  const [ready, setReady] = useState(false);
  const [sendCtx, setSendCtx] = useState(false);
  const [sendLocation, setSendLocation] = useState(false);
  const [bundleUrl, setBundleUrl] = useState('');
  const [fatalError, setFatalError] = useState(false);
  const [to, setTo] = useState('');
  const previousAccessToken = usePrevious(ctx.accessToken);
  const previousModuleId = usePrevious(moduleId);

  useEffect(() => {
    // if our session has been refreshed, we should send
    // the new context down to the module
    if (ready && ctx.accessToken !== previousAccessToken) {
      console.info('sending updated context to module');
      setSendCtx(true);
    }
  }, [ctx.accessToken, previousAccessToken, ready]);

  useEffect(() => {
    function handleModuleWindowEvent(ev) {
      if (!iframeEl.current) {
        return;
      }

      if (ev.data && ev.data.source === iframeEl.current.name) {
        console.log('from module window:', ev.data.payload);
        if (ev.data.type === 'ready') {
          setReady(true);
          setSendCtx(true);
          setSendLocation(true);
        } else if (ev.data.type === 'route') {
          setTo(ev.data.payload);
        }
      }
    }

    window.addEventListener('message', handleModuleWindowEvent);

    return () => {
      window.removeEventListener('message', handleModuleWindowEvent);
    };
  }, []);

  useEffect(() => {
    if (
      iframeEl.current &&
      iframeEl.current.contentWindow &&
      typeof iframeEl.current.contentWindow.postMessage === 'function'
    ) {
      iframeEl.current.contentWindow.postMessage({
        source: 'portal',
        type: 'location',
        payload: location
      });
    }
  }, [location]);

  useEffect(() => {
    if (sendCtx) {
      iframeEl.current.contentWindow.postMessage({
        source: 'portal',
        type: 'context',
        payload: ctx
      });
      setSendCtx(false);
    }
    if (sendLocation) {
      iframeEl.current.contentWindow.postMessage({
        source: 'portal',
        type: 'location',
        payload: location
      });
      setSendLocation(false);
    }
    if (to) {
      dispatch(push(to));
      setTo('');
    }
  }, [ctx, dispatch, location, sendCtx, sendLocation, to]);

  useEffect(() => {
    const fetchModule = (moduleId, ctx) => {
      setBundleUrl('');
      setFatalError(false);

      fetch(`${ctx.apiBaseUrl}/modules/${moduleId}`, {
        headers: {
          Authorization: `Bearer ${ctx.accessToken}`,
          'x-fv-userid': ctx.userId,
          'x-fv-orgid': ctx.orgId,
          'x-fv-sessionid': ctx.refreshToken
        }
      })
        .then(x => x.json())
        .then(mod => {
          setBundleUrl(mod.bundle);
        })
        .catch(error => {
          console.error('Failed to get module', error);
          setFatalError(true);
        });
    };
    if (ctx && ctx.accessToken && moduleId && moduleId !== previousModuleId) {
      fetchModule(moduleId, ctx);
    }
  }, [ctx, moduleId, previousModuleId]);

  if (fatalError) {
    return (
      <Container className="my-3">
        <Alert variant="danger">
          <Alert.Heading>Ouch! Something went wrong.</Alert.Heading>
          <p>We're not able to load this module right now.</p>
          <p className="mb-0">
            Please try again later or{' '}
            <a
              href={`mailto:api-help@filevine.com?subject=Unable to load portal module ${path} (${moduleId})`}
              className="alert-link"
            >
              contact support
            </a>
            .
          </p>
        </Alert>
      </Container>
    );
  }

  return (
    <iframe
      ref={iframeEl}
      id="module-window"
      name="module-window"
      title="content"
      frameBorder="0"
      className="module-window-frame"
      srcDoc={`<!DOCTYPE html>
<head>
  ${stylesheets
    .map(({ href }) => `<link rel="stylesheet" href="${href}" />`)
    .join('\n')}
  ${styleNodes.map(node => `<style>${node.innerHTML}</style>`).join('\n')}
  <style>
    @keyframes moduleSpinner {
      from { transform: rotate(0deg); }
      to { transform: rotate(360deg); }
    }

    .module-spinner {
      display: block;
      width: 2rem;
      height: 2rem;
      border: 5px solid rgba(0, 0, 0, 0.1);
      border-top-color: rgba(0, 0, 0, 0.5);
      border-radius: 100%;
      position: fixed;
      top: calc(50vh - 1rem);
      left: calc(50vw - 1rem);
      animation: moduleSpinner 1s linear infinite;
    }
  </style>
</head>
<body id="module-body">
<div id="root">
  <span class="module-spinner"></span>
</div>
<script src="${bundleUrl}"></script>
</body>`}
    />
  );
};

export default connect(state => ({
  location: state.router.location,
  ctx: {
    apiBaseUrl: process.env.REACT_APP_GATEWAY_URL,
    apiUserId: state.auth.activeOrg.userId,
    userId: state.auth.userId,
    orgId: state.auth.orgId,
    orgPk: state.auth.activeOrg.pk,
    accessToken: state.auth.accessToken,
    refreshToken: state.auth.refreshToken,
    scopes: state.auth.accessTokenScopes
  }
}))(ModuleFrame);
