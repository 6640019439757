import React, { useContext, useReducer } from 'react';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import wait from 'waait';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import delve from 'dlv';

import {
  StateContext,
  DispatchContext,
  MODULES_RESOLVED,
  SYSTEM_MODULES
} from './ModulesContext';
import ModuleForm from './ModuleForm';

const ONE_SECOND = 1000;

const RESET = 'RESET';
const WORKING = 'WORKING';
const COMPLETED = 'COMPLETED';
const ERRORED = 'ERRORED';

const initialWorkingState = { isWorking: false, isError: false, data: null };
const workingReducer = (state = initialWorkingState, { type, payload }) => {
  switch (type) {
    case RESET:
      return initialWorkingState;
    case WORKING:
      return { ...initialWorkingState, isWorking: true };
    case COMPLETED:
      return { ...state, isWorking: false, data: payload };
    case ERRORED:
      return { ...state, isWorking: false, isError: true };
    default:
      return state;
  }
};

const EditModule = ({
  isPortalAdmin,
  ctx,
  routeTo,
  match: {
    params: { id }
  }
}) => {
  const [deleteState, dispatchDelete] = useReducer(
    workingReducer,
    initialWorkingState
  );
  const [saveState, dispatchSave] = useReducer(
    workingReducer,
    initialWorkingState
  );
  const state = useContext(StateContext);
  const dispatchModules = useContext(DispatchContext);
  const moduleValues = state.data.find(m => m.moduleId === id || m.path === id);
  const initialValues = moduleValues
    ? {
        ...moduleValues,
        scopes:
          typeof moduleValues.scopes === 'string' ? moduleValues.scopes : '',
        orgs:
          typeof moduleValues.orgs === 'string' && moduleValues.orgs.length
            ? moduleValues.orgs.split(',')
            : [],
        moduleType: moduleValues.user
          ? 'user'
          : moduleValues.admin
          ? 'admin'
          : 'portal'
      }
    : null;

  const handleDelete = async moduleId => {
    if (
      moduleId &&
      window.confirm('Are you sure you want to delete this module?')
    ) {
      try {
        dispatchDelete({ type: WORKING });

        const res = await fetch(`${ctx.apiBaseUrl}/modules/${moduleId}`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${ctx.accessToken}`,
            'x-fv-userid': ctx.userId,
            'x-fv-orgid': ctx.orgId,
            'x-fv-sessionid': ctx.refreshToken
          }
        });

        if (!res.ok) {
          throw res;
        }

        dispatchDelete({
          type: COMPLETED,
          payload: { success: true, moduleId }
        });
      } catch (error) {
        console.error(error);
        dispatchDelete({ type: ERRORED });
      }
    }
  };

  const completeDelete = moduleId => {
    dispatchModules({
      type: MODULES_RESOLVED,
      payload: state.data.filter(m => m.moduleId !== moduleId)
    });
    routeTo('/systemmodules');
  };

  const handleSubmit = async ({ bundle, newBundle, orgs, path, ...values }) => {
    try {
      const payload = {
        ...values,
        path: '/' + path.replace(/^\//, ''),
        orgs: orgs.join(',')
      };
      if (newBundle) {
        payload.filename = newBundle.name;
        payload.type = newBundle.type;
      }

      dispatchSave({ type: WORKING });

      // make the PATCH request
      const patchRes = await fetch(
        `${ctx.apiBaseUrl}/modules/${values.moduleId}`,
        {
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${ctx.accessToken}`,
            'x-fv-userid': ctx.userId,
            'x-fv-orgid': ctx.orgId,
            'x-fv-sessionid': ctx.refreshToken,
            'content-type': 'application/json'
          },
          body: JSON.stringify(payload)
        }
      );

      if (!patchRes.ok) {
        throw patchRes;
      }

      let updatedModule = await patchRes.json();

      // if we are uploading a new bundle, we need to make
      // a PUT request to the pre-signed S3 URL, then refetch
      // the updated module data
      if (newBundle) {
        const uploadRes = await fetch(updatedModule.uploadUrl, {
          method: 'PUT',
          headers: {
            'content-type': newBundle.type,
            'content-size': newBundle.size
          },
          body: newBundle
        });

        if (!uploadRes.ok) {
          throw uploadRes;
        }

        await wait(ONE_SECOND);

        const getRes = await fetch(
          `${ctx.apiBaseUrl}/modules/${updatedModule.moduleId}`,
          {
            headers: {
              Authorization: `Bearer ${ctx.accessToken}`,
              'x-fv-userid': ctx.userId,
              'x-fv-orgid': ctx.orgId,
              'x-fv-sessionid': ctx.refreshToken
            }
          }
        );

        if (!getRes.ok) {
          throw getRes;
        }

        updatedModule = await getRes.json();
      }

      dispatchSave({ type: COMPLETED, payload: true });

      // update the modules in context with the new updated module info
      dispatchModules({
        type: MODULES_RESOLVED,
        payload: state.data.map(m =>
          m.moduleId === updatedModule.moduleId ? updatedModule : m
        )
      });
    } catch (error) {
      console.error('Failed to save the module:', error);
      dispatchSave({ type: ERRORED });
    }
  };

  if (!initialValues) {
    return null;
  }

  const canSaveAndDelete =
    isPortalAdmin || SYSTEM_MODULES.every(path => path !== initialValues.path);
  if (!canSaveAndDelete) {
    return (
      <Container>
        <Alert variant="danger" className="mt-4">
          <Alert.Heading>Whoa there!</Alert.Heading>
          <p className="mb-0">You aren't allowed to edit this module.</p>
        </Alert>
      </Container>
    );
  }

  return (
    <Container>
      <h3 className="m-0 py-4 bg-white sticky-top">Edit Module</h3>
      {!!deleteState.data && deleteState.data.success ? (
        <Alert
          variant="success"
          dismissible
          onClose={() => completeDelete(deleteState.data.moduleId)}
        >
          <Alert.Heading>Success!</Alert.Heading>
          <p className="mb=0">The module was successfully deleted.</p>
        </Alert>
      ) : (
        <>
          {saveState.data && (
            <Alert
              variant="success"
              dismissible
              onClose={() => dispatchSave({ type: RESET })}
            >
              <Alert.Heading>Success!</Alert.Heading>
              <p className="mb-0">The module was successfully saved.</p>
            </Alert>
          )}
          <ModuleForm initialValues={initialValues} onSubmit={handleSubmit}>
            <Button
              type="submit"
              disabled={saveState.isWorking || deleteState.isWorking}
            >
              {saveState.isWorking ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <span className="sr-only">Saving...</span>
                </>
              ) : (
                'Save'
              )}
            </Button>
            <Button
              type="button"
              variant="danger"
              className="ml-2"
              onClick={() => handleDelete(initialValues.moduleId)}
              disabled={saveState.isWorking || deleteState.isWorking}
            >
              {deleteState.isWorking ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <span className="sr-only">Deleting...</span>
                </>
              ) : (
                'Delete'
              )}
            </Button>
          </ModuleForm>
        </>
      )}
    </Container>
  );
};

export default connect(
  state => ({
    ctx: {
      apiBaseUrl: process.env.REACT_APP_GATEWAY_URL,
      apiUserId: delve(state, 'auth.activeOrg.userId'),
      userId: state.auth.userId,
      orgId: state.auth.orgId,
      orgPk: delve(state, 'auth.activeOrg.pk'),
      accessToken: state.auth.accessToken,
      refreshToken: state.auth.refreshToken,
      scopes: state.auth.accessTokenScopes
    }
  }),
  dispatch => ({
    routeTo(to) {
      dispatch(push(to));
    }
  })
)(EditModule);
