import React, { useContext, useReducer, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import wait from 'waait';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import {
  StateContext,
  DispatchContext,
  MODULES_BUSY,
  MODULES_RESOLVED,
  MODULES_REJECTED
} from './ModulesContext';
import ModuleForm from './ModuleForm';

const ONE_SECOND = 1000;

const initialSaveState = { isSaving: false, isError: false, data: null };
const saveReducer = (state = initialSaveState, { type, payload }) => {
  switch (type) {
    case 'module/SAVING':
      return { ...initialSaveState, isSaving: true };
    case 'module/SAVED':
      return { ...state, isSaving: false, data: payload };
    case 'module/ERRORED':
      return { ...state, isSaving: false, isError: true };
    default:
      return state;
  }
};

const CreateModule = ({ ctx, dispatch }) => {
  const [errors, setErrors] = useState({});
  const [saveState, dispatchSave] = useReducer(saveReducer, initialSaveState);
  const state = useContext(StateContext);
  const dispatchModules = useContext(DispatchContext);

  const initialValues = {
    icon: '',
    name: '',
    description: '',
    moduleType: 'portal',
    path: '',
    scopes: '',
    orgs: []
  };

  const handleSubmit = ({ newBundle, moduleType, orgs, path, ...values }) => {
    setErrors({});
    dispatchSave({ type: 'module/SAVING' });
    dispatchModules({ type: MODULES_BUSY });
    fetch(`${ctx.apiBaseUrl}/modules`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${ctx.accessToken}`,
        'x-fv-userid': ctx.userId,
        'x-fv-orgid': ctx.orgId,
        'x-fv-sessionid': ctx.refreshToken,
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        ...values,
        path: '/' + path.replace(/^\//, ''),
        orgs: orgs.join(','),
        user: moduleType === 'user',
        admin: moduleType === 'admin',
        enabled: true
      })
    })
      .then(async res => {
        if (res.status === 400) {
          const errors = await res.json();
          setErrors(errors);
          throw errors;
        } else if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then(newModule => {
        console.log('Uploading to:', newModule.uploadUrl);
        return fetch(newModule.uploadUrl, {
          method: 'PUT',
          headers: {
            'content-type': newBundle.type,
            'content-size': newBundle.size
          },
          body: newBundle
        }).then(res => {
          if (!res.ok) {
            return fetch(`${ctx.apiBaseUrl}/modules/${newModule.moduleId}`, {
              method: 'DELETE',
              headers: {
                Authorization: `Bearer ${ctx.accessToken}`,
                'x-fv-userid': ctx.userId,
                'x-fv-orgid': ctx.orgId,
                'x-fv-sessionid': ctx.refreshToken
              }
            }).then(res => {
              if (!res.ok) {
                throw new Error('Failed to clean up incomplete module');
              }
              throw new Error('Failed to upload bundle');
            });
          }
          return wait(ONE_SECOND)
            .then(() =>
              fetch(`${ctx.apiBaseUrl}/modules/${newModule.moduleId}`, {
                headers: {
                  Authorization: `Bearer ${ctx.accessToken}`,
                  'x-fv-userid': ctx.userId,
                  'x-fv-orgid': ctx.orgId,
                  'x-fv-sessionid': ctx.refreshToken
                }
              })
            )
            .then(x => x.json());
        });
      })
      .then(newModule => {
        dispatchSave({ type: 'module/SAVED', payload: newModule });
        dispatchModules({
          type: MODULES_RESOLVED,
          payload: [...state.data, newModule]
        });
        dispatch(push(`/systemmodules/${newModule.moduleId}`));
      })
      .catch(error => {
        console.error('Failed to save module', error);
        dispatchSave({ type: 'module/ERRORED' });
        dispatchModules({ type: MODULES_REJECTED });
      });
  };

  return (
    <Container>
      <h3 className="m-0 py-4 bg-white sticky-top border-bottom">New Module</h3>
      <ModuleForm initialValues={initialValues} onSubmit={handleSubmit}>
        <Button type="submit" disabled={saveState.isSaving}>
          {saveState.isSaving ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <span className="sr-only">Saving...</span>
            </>
          ) : (
            'Add'
          )}
        </Button>
        {Object.keys(errors).length > 0 && (
          <Alert variant="danger" className="mt-4 animated fadeIn">
            <Alert.Heading>Correct the following errors:</Alert.Heading>
            <ul>
              {Object.keys(errors).map(key => (
                <li key={key}>{errors[key]}</li>
              ))}
            </ul>
          </Alert>
        )}
      </ModuleForm>
    </Container>
  );
};

export default connect(state => ({
  ctx: {
    apiBaseUrl: process.env.REACT_APP_GATEWAY_URL,
    apiUserId: state.auth.activeOrg.userId,
    userId: state.auth.userId,
    orgId: state.auth.orgId,
    orgPk: state.auth.activeOrg.pk,
    accessToken: state.auth.accessToken,
    refreshToken: state.auth.refreshToken,
    scopes: state.auth.accessTokenScopes
  }
}))(CreateModule);
