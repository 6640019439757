import { createContext } from 'react';

export const initialOrgsState = {
  isStale: false,
  isLoading: true,
  isError: false,
  data: []
};

export const OrgsStateContext = createContext(initialOrgsState);
export const OrgsDispatchContext = createContext(() => {});

export const ORGS_STALE = 'ORGS_STALE';
export const ORGS_LOADING = 'ORGS_LOADING';
export const ORGS_LOADED = 'ORGS_LOADED';
export const ORGS_ERRORED = 'ORGS_ERRORED';

export function orgsReducer(state, { type, payload }) {
  switch (type) {
    case ORGS_STALE:
      return { ...state, isStale: true };
    case ORGS_LOADING:
      return initialOrgsState;
    case ORGS_LOADED:
      return { ...state, isLoading: false, data: payload };
    case ORGS_ERRORED:
      return { ...state, isLoading: false, isError: true };
    default:
      return state;
  }
}
