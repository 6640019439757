import React, { Fragment } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';
import Jumbotron from 'react-bootstrap/Jumbotron';
import delve from 'dlv';

import Home from './components/Home';
import SystemModules from './systemModules';
import ModuleFrame from './components/ModuleFrame';

function VerticallyCenteredContent({ children }) {
  return (
    <div className="d-flex w-100 w-md-50 align-items-center justify-content-center">
      {children}
    </div>
  );
}

function HangTight() {
  return (
    <Container>
      <Jumbotron>
        <h1>Hang tight!</h1>
        <div className="d-flex align-items-center">
          <Spinner
            animation="border"
            variant="secondary"
            size="sm"
            className="mx-2 my-3"
          />
          {' Loading your content'}
        </div>
      </Jumbotron>
    </Container>
  );
}

function Routes({ ctx, user, loggedIn, modules, modulesLoading }) {
  const Wrapper = loggedIn ? Fragment : VerticallyCenteredContent;

  return (
    <Wrapper>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route
          path="/systemmodules"
          render={routeProps => <SystemModules {...routeProps} ctx={ctx} />}
        />
        <Route
          path="/:path"
          render={props => {
            const {
              match: {
                params: { path }
              }
            } = props;
            const modulePath = '/' + path;
            const mod = modules.find(m => m.path === modulePath);

            if (!mod) {
              return modulesLoading || !loggedIn ? (
                <HangTight />
              ) : (
                <Redirect to="/" />
              );
            }

            return <ModuleFrame {...props} {...mod} user={user} />;
          }}
        />
        <Redirect to="/" />
      </Switch>
    </Wrapper>
  );
}

export default connect(state => ({
  modulesLoading: state.modules.loading,
  modules: state.modules.data,
  loggedIn: state.auth.valid && !state.auth.busy,
  busy: state.auth.busy,
  valid: state.auth.valid,
  ctx: {
    apiBaseUrl: process.env.REACT_APP_GATEWAY_URL,
    apiUserId: delve(state, 'auth.activeOrg.userId', ''),
    userId: state.auth.userId,
    orgId: state.auth.orgId,
    orgPk: delve(state, 'auth.activeOrg.pk', ''),
    accessToken: state.auth.accessToken,
    refreshToken: state.auth.refreshToken,
    scopes: state.auth.accessTokenScopes
  }
}))(Routes);
