import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';

import { StateContext, SYSTEM_MODULES } from './ModulesContext';

const ListModules = ({ isPortalAdmin }) => {
  const state = useContext(StateContext);

  const viewableModules =
    !state.isLoading && Array.isArray(state.data) && state.data.length > 0
      ? state.data.filter(
          mod =>
            isPortalAdmin || SYSTEM_MODULES.every(path => path !== mod.path)
        )
      : [];

  return (
    <Container>
      <div className="w-100 my-4 d-flex align-items-baseline justify-content-between">
        <span className="h3">Modules</span>
        <Link className="btn btn-primary" to="/systemmodules/new">
          Add module
        </Link>
      </div>
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Path</th>
          </tr>
        </thead>
        <tbody>
          {state.isLoading && (
            <tr>
              <td>Loading...</td>
            </tr>
          )}
          {state.isError && (
            <tr>
              <td>Unable to load modules!</td>
            </tr>
          )}
          {!state.isLoading && viewableModules.length === 0 && (
            <tr>
              <td>There no modules.</td>
            </tr>
          )}
          {viewableModules.map(mod => (
            <tr key={mod.moduleId}>
              <td>
                {isPortalAdmin ||
                SYSTEM_MODULES.every(path => path !== mod.path) ? (
                  <Link to={`/systemmodules/${mod.moduleId}`}>{mod.name}</Link>
                ) : (
                  <span>{mod.name}</span>
                )}
              </td>
              <td>{mod.path}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default ListModules;
