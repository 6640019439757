import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Auth, I18n } from 'aws-amplify';
import {
  Authenticator,
  SignIn,
  SignUp,
  Greetings,
  TOTPSetup
} from 'aws-amplify-react';
import amplifyTheme from './amplify-theme';

import Navigation from './Navigation';
import Routes from './Routes';
import pkg from '../package.json';
import { MySignIn, MyTOTPSetupComp } from './auth';
import Spinner from 'react-bootstrap/Spinner';
import FormContainer from './components/FormContainer';

// setup internationalization
I18n.setLanguage('en');
I18n.putVocabularies({
  en: {
    // Sign In
    AWSCognito: window.location.hostname,
    'Scan then enter verification code': 'Setup portal MFA.',
    'Sign in to your account': 'Filevine Developer Portal',
    'Enter your email': ' ',
    'Enter your password': ' '
  }
});

function Footer({ year = new Date().getFullYear() }) {
  const [gatewayVersion, setGatewayVersion] = useState('...');

  useEffect(() => {
    fetch(`${process.env.REACT_APP_GATEWAY_URL}/version`)
      .then(x => x.json())
      .then(({ version }) => setGatewayVersion(version))
      .catch(() => setGatewayVersion('?.?.?'));
  }, []);

  return (
    <div className="fixed-bottom font-weight-light shadow-lg bg-secondary text-white d-flex align-items-center justify-content-between px-3">
      <div>&copy; {year} Filevine, Inc.</div>
      <div>
        Portal v{pkg.version} / Gateway v{gatewayVersion}
      </div>
    </div>
  );
}

function App({ dispatch, authState, valid }) {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const initialize = async () => {
      try {
        setUser(await Auth.currentAuthenticatedUser());

        const session = await Auth.currentSession();
        console.log(session);
        const context = await fetch(
          `${process.env.REACT_APP_GATEWAY_URL}/auth/me`,
          {
            headers: {
              Authorization: `Bearer ${session.accessToken.jwtToken}`
            }
          }
        ).then(x => x.json());

        dispatch({
          type: 'cognito/INITIALIZED',
          payload: { session, context }
        });
      } catch (error) {
        console.error('unable to load cognito session/context', error);
        throw error;
      }
    };

    if (authState === 'signedIn') {
      initialize();
    }
  }, [authState, dispatch]);

  useEffect(() => {
    if (user) {
      user.getCachedDeviceKeyAndPassword();
      if (user.deviceKey) {
        user.listDevices(10, null, {
          onSuccess({ Devices }) {
            const notYetRemembered = Devices.every(
              d => d.DeviceKey !== user.deviceKey
            );
            if (notYetRemembered) {
              const shouldRemember = window.confirm(
                'Would you like us to remember this device?'
              );
              if (shouldRemember) {
                user.setDeviceStatusRemembered({
                  onSuccess(result) {
                    console.log('device remembered:', result);
                  },
                  onFailure(err) {
                    console.error('unable to remember device:', err);
                  }
                });
              } else {
                console.log('user opted not to remember');
              }
            } else {
              console.log('device already remembered!', user.deviceKey);
            }
          },
          onFailure(err) {
            console.error('unable to list devices:', err);
          }
        });
      }
    }
  }, [user]);

  return (
    <>
      {authState === 'signedIn' && (
        <div className="d-block d-md-flex min-vh-100 pb-4">
          {valid ? (
            <>
              <Navigation />
              <Routes user={user} />
            </>
          ) : (
            <FormContainer>
              <Spinner animation="border" size="lg" variant="light" />
            </FormContainer>
          )}
        </div>
      )}
      <Footer />
    </>
  );
}

const ConnectedApp = connect(state => ({
  valid: state.auth.valid
}))(App);

class MyTOTPSetup extends TOTPSetup {
  showComponent(theme) {
    return (
      <MyTOTPSetupComp
        {...this.props}
        onTOTPEvent={(...args) => {
          if (args[1] === 'SUCCESS') {
            window.alert(
              'Portal MFA was successfully set up! Please sign in to complete your account setup.'
            );
            Auth.signOut().catch(err => {
              console.error('Problem signing out:', err);
            });
          } else {
            this.onTOTPEvent(...args);
          }
        }}
      />
    );
  }
}

export default ({ onStateChange }) => (
  <Authenticator
    usernameAttributes="email"
    hide={[SignIn, SignUp, Greetings, TOTPSetup]}
    theme={amplifyTheme}
    onStateChange={onStateChange}
  >
    <MySignIn />
    <MyTOTPSetup />
    <ConnectedApp />
  </Authenticator>
);
