import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { Provider } from 'react-redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createBrowserHistory } from 'history';
import {
  connectRouter,
  routerMiddleware,
  ConnectedRouter
} from 'connected-react-router';
import reduxIdleDispatcher from 'redux-idle-dispatcher';
import Amplify, { Auth } from 'aws-amplify';
import delve from 'dlv';

import App from './App';
import authReducer from './redux/authReducer';
import modulesReducer from './redux/modulesReducer';
import requestMiddleware from './redux/requestMiddleware';

import 'bootstrap/dist/css/bootstrap.css';
import 'animate.css';
import './styles.css';

if (!process.env.REACT_APP_GATEWAY_URL) {
  throw new Error('Missing gateway URL');
}

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID
  }
});

export const history = createBrowserHistory();

export const store = createStore(
  combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    modules: modulesReducer
  }),
  composeWithDevTools(
    applyMiddleware(
      routerMiddleware(history),
      thunkMiddleware,
      requestMiddleware()
    )
  )
);

reduxIdleDispatcher(store, 5000);

function Wrapper() {
  const [state, setState] = useState('signIn');

  useEffect(() => {
    if (state === 'signedIn') {
      return store.subscribe(async () => {
        try {
          const state = store.getState();
          const expired = delve(state, 'auth.accessTokenExpired', false);
          if (expired) {
            const session = await Auth.currentSession();
            store.dispatch({ type: 'cognito/REFRESHED', payload: { session } });
          }
        } catch (error) {
          Auth.signOut();
        }
      });
    }
  }, [state]);

  return <App onStateChange={nextState => setState(nextState)} />;
}

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Wrapper />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);
